*,
html,
body {
    padding: 0;
    margin: 0;
}

body {
    background-color: rgb(254, 254, 254);
}

.data-fetch-msg {
    text-align: center;
    font-family: 'Roboto-mono', sans-serif;
    font-size: 2rem;
    margin: 5vh 0 15vh 0;
}
.Hero {
    padding: 2vh 5%;
}

.Hero-child-one {
    max-width: 1200px; 
    margin: 0 auto;
    margin-top: 6vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Hero-child-one-one {
    color: #312929;
    margin-left: 5%;
}

.Hero-child-one-two {
    margin-right: 5%;
    max-width: 20%;
}

.Hero-child-text {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 3.5rem;
}

.Hero-child-one-two img {
    width: 100%;
}

.Hero-black {
    color: black;
}

.Hero-child-three {
    max-width: 1050px;
    margin: 0 auto;
    text-align: center;
    margin-top: 6vh;
    margin-bottom: 6vh;
}

.Hero-sub-text {
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
    font-size: 1.3rem;
    color: rgb(17, 17, 17);
}

.Hero-sub-text-bold {
    font-family: 'Open sans', sans-serif;
    font-size: 1.6rem;
    color: rgb(17, 17, 17);
    font-weight: 600;
}

.Hero-elip {
    color: #FF5678;
}


@media (max-width: 980px) {
    .Hero-child-text {
        font-size: 2.5rem;
    }
    .Hero-child-one {
        margin-top: 7vh;
    }
    .Hero-sub-text {
        font-size: 1.1rem;
    }
    .Hero-sub-text-bold {
        font-size: 1.4rem;
    }
    .Hero-child-three {
        margin-bottom: 4vh;
    }
}

@media (max-width: 530px) {
    .Hero-child-text {
        font-size: 2.5rem;
    }
    .Hero-child-one-one {
        margin-bottom: 3vh;
    }
    .Hero-child-one-two {
        margin: 0;
        max-width: 35%;
    }
    .Hero-child-one {
        flex-direction: column;
    }
    .Hero-child-two {
        margin-top: 4vh;
    }
    .Hero-child-three {
        margin-top: 4vh;
        margin-bottom: 2vh;
    }
    .Hero-sub-text {
        font-size: 0.9rem;
    }

}

@media (max-width: 400px) {
    .Hero-child-text {
        font-size: 2rem;
    }
}
.Section-header {
    font-family: 'Roboto', sans-serif;
    font-size: 2.6rem;
    font-weight: 500;
    text-align: center;
}

.Section {
    margin: 0 auto;
    max-width: 1200px;
    height: 250px;
    margin-bottom: 10.5vh;
    margin-top: 2.5vh;
    display: flex;
    align-items: center;
}


.first-section-span {
    color: #F17171;
}

.second-section-span {
    color: #9B90FF;
}

.third-section-span {
    color: #D39113;
} 

@media (max-width: 970px) {
    .Section-header {
        font-size: 1.8rem;
    }
}


@media (max-width: 730px) {
    .Section-header {
        margin-top: 2vh;
        font-size: 1.5rem;
    }
    .Section {
        height: 25vh;
    }
}

@media (max-width: 530px) {

    .Section {
        height: 20vh;
    }
}

@media (max-width: 400px) {

    .Section {
        height: 16vh;
        margin-bottom: 6vh;
    }

    .Section-header {
        margin-top: 1.5vh;
    }
}
.first-section {
    border: 7px solid #FF716B;
}

.first-section-title {
    color: #FF716B;
}

.second-section {
    border: 7px solid #9B90FF;
}

.second-section-title {
    color: #9B90FF;
}

.third-section {
    border: 7px solid #D39113;
}

.third-section-title {
    color: #D39113;
}

.SectionChild {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    background-color: #241F1F;
    width: 18%;
    height: 85%;
    margin: 0 3%;
}

.SectionChild-title {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    text-align: center;
    font-size: 1.8rem;
}



.SectionChild-blurb {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    text-align: center;
    font-size: 0.7rem;
    color: #D6D4D4;
}


.SectionChild-c-1 {
    padding: 2% 0.5vw;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 15%;
}

.SectionChild-c-2 {
    height: 15%;
    display: flex;
    align-items: center;
    padding: 0.25vw 0.25vw;
}

.SectionChild:hover {
    cursor: pointer;
}

@media (max-width: 950px) {
    .SectionChild-title {
        font-size: 1.2rem;
    }
    .SectionChild-blurb {
        font-size: 0.8rem;
    }
}

@media (max-width: 700px) {
    .SectionChild-title {
        font-size: 1rem;
    }
    .SectionChild-blurb {
        font-size: 0.7rem;
    }
    .SectionChild-c-1 {
        height: 40%;
    }
    .SectionChild-c-3 {
        height: 0%;
    }
    .SectionChild-c-2 {
        margin-bottom: 1vh;
        height: 60%;
    }
}

@media (max-width: 500px) {
    .SectionChild-title {
        font-size: 1rem;
        padding: 0.5vh 0;
    }
    .SectionChild-blurb {
        font-size: 0.6rem;
    }
    .SectionChild-c-1 {
        height: 50%;
    }
    .SectionChild-c-2 {
        margin-bottom: 1vh;
        height: 40%;
    }
}

@media (max-width: 400px) {
    .SectionChild-title {
        font-size: 0.8rem;
    }
    .SectionChild-blurb {
        font-size: 0.45rem;
    }
    .SectionChild-c-2 {
        margin: 0;
    }
    .SectionChild-c-1 {
        padding: 0 0.5vw;
    }


}
.SectionChildTag {
    height: 65%;
    padding: 1% 3.5%;
    margin: 0% 1%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    color: white;
    background-color: #525252;
}

.SectionChildTag p {
    font-family: 'Inter', sans-serif;
    font-size: 0.7rem;
}


@media (max-width: 950px) {
    .SectionChildTag p {
        font-size: 0.7rem;
    }
}

@media (max-width: 700px) {
    .SectionChildTag {
        display: none;
    }
}
.Header {
    background-color: rgb(255, 255, 255);
    position: fixed;
    width: 100%;
    height: 25px;
    padding: 1.5vh 0;
    padding-bottom: 2vh;
}

.Header-child {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
}

.Header-child-logo {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
}

.Header-child-logo span {
    color: rgb(75, 74, 74);
    font-weight: 500;
}

.Header-child-contact {
    background-color: #000000;
    border-radius: 3px;
    color: #fff;
    height: 30px;
    font-size: 0.8rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
}

.Header-child-contact:hover {
    cursor: pointer;
    background-color: #FF5678;
}

.Header-child-contact p {
    padding: 6px 10px;
}

.Header-child-logo:hover {
    cursor: pointer;
}

@media (max-width: 1200px) {
    .Header-child-contact {
        margin-right: 2%;
    }
}

@media (max-width: 530px) {
    .Header-child-logo {
        font-size: 1rem;
    }
    .Header-child-contact {
        height: 25px;
        font-size: 0.7rem;
    }
    .Header-child-contact p {
        padding-top: 5px
    }
}

@media (max-width: 420px) {
    .Header-child-logo {
        font-size: 0.9rem;
    }
    .Header-child-contact {
        font-size: 0.6rem;
    }
    .Header-child-contact p {
        padding-top: 6px;
    }
}

@media (max-width: 350px) {
    .Header-child-contact {
        font-size: 0.55rem;
    }
    .Header-child-contact p {
        padding-top: 7px;
    }
}
.Footer {
    height: 25vh;
    min-height: 350px;
    padding: 10px;
    background-color: #002734;
    text-align: center;
    color: white;
    font-family: 'Open Sans', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.title {
    font-size: 1.5rem;
    letter-spacing: 0.3rem;
}

.title span {
    color: rgb(194, 194, 194);
    font-weight: 500;
}


.list-item {
    font-size: 1rem;
    display: inline;
    padding: 0 25px;
    letter-spacing: 0.2rem;
}

.list-item a {
    text-decoration: none;
    color: white;
}

.list-item a:hover {
    cursor: pointer;
}

.location {
    font-size: 0.9rem;
    letter-spacing: 0.15rem;
}

.title,
.list,
.location {
    padding: 1.8vh 0;
}


@media (max-width: 730px) {
    .title {
        font-size: 1rem;
    }
    .quote {
        font-size: 0.9rem;
    }
    .note {
        font-size: 0.8rem;
        max-width: 500px;
    }
    .list {
        padding-top: 30px;
    }
    .list-item {
        padding: 0 20px;
        font-size: 0.9rem;
    }
    .Footer {
        height: 30vh;
        padding: 5px;
    }
}

@media (max-width: 400px) {
    .list-item {
        font-size: 0.8rem;
        padding: 0 15px;
    }
}


.Article {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 12vh;
    margin-bottom: 0;
    padding-bottom: 4vh;
    width: 100%;
    background-color: #ffe8e8;
    min-height: 50vh;
}

.Article-Interesting.Reads {
    background-color: #F2E6E1;
}

.Article-Drawing.Board {
    background-color: #DBEFF1;
}

.Article-container {
    width: 100%;
    max-width: 1200px;
    padding: 0 5%;
}

.Article-c-1 {
    background-color: #FF716B;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    display: flex;
    align-items: center;
    border-radius: 5px;
}

.Article-c-1-Drawing.Board {
    background-color: #9B90FF;
}

.Article-c-1-Interesting.Reads {
    background-color: #D39113;
}

.Article-c-1-1 {
    padding: 2vh 2vw;
}

.Article-title {
    font-size: 2rem;
    font-weight: 600;
}

.Article-section {
    font-size: 1.2rem;
    font-weight: 500;
}

.Article-date {
    font-weight: 400;
}

.Article-text {
    font-size: 1rem;
    margin: 2vh 2vw;
}


.Article-c-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 4% 0;
 }

 .Article-c-2-1,
 .Article-c-2-2 {
    background-color: #29292a;
    width: 48%;
    border-radius: 5px;
    color: rgb(247, 247, 247);
    font-family: 'Inter', sans-serif;
    font-size: 1.1rem;
 }

 .Article-c-2-1 {
    min-height: 60vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
 }

 .Article-c-2-2 {
    padding: 1vh 0;
    display: flex;
    align-items: center;
    flex-flow: column;
 }


 .Article-images {
    max-height: 30vh;
    width: 60%;
    margin: 2vh 3vw;
    border-radius: 5px;
 }

 .Article-links {
    display: block;
    text-decoration: none;
    color: rgb(118, 115, 115);
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    margin: 2vh 2vw;
 }

 @media (max-width: 800px) {
    .Article-c-2 {
        flex-direction: column;
        align-items: center;
    }
    .Article-title {
        font-size: 1.8rem;
    }
    .Article-c-2-1,
    .Article-c-2-2 {
        width: 85%;
        margin: 2vh 0;
    }
    .Article-c-1 {
        width: 85%;
        margin: 0 auto;
    }
 }

 @media (max-width: 600px) {
    .Article-text,
    .Article-links {
        font-size: 0.7rem;
    }
    .Article-title {
        font-size: 1.5rem;
    }
    .Article-images {
        width: 80%;
     }
 }

 @media (max-width: 600px) {
    .Article-text,
    .Article-links {
        font-size: 0.7rem;
    }
    .Article-title {
        font-size: 1.2rem;
    }
 }
