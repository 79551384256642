.Article {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 12vh;
    margin-bottom: 0;
    padding-bottom: 4vh;
    width: 100%;
    background-color: #ffe8e8;
    min-height: 50vh;
}

.Article-Interesting.Reads {
    background-color: #F2E6E1;
}

.Article-Drawing.Board {
    background-color: #DBEFF1;
}

.Article-container {
    width: 100%;
    max-width: 1200px;
    padding: 0 5%;
}

.Article-c-1 {
    background-color: #FF716B;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    display: flex;
    align-items: center;
    border-radius: 5px;
}

.Article-c-1-Drawing.Board {
    background-color: #9B90FF;
}

.Article-c-1-Interesting.Reads {
    background-color: #D39113;
}

.Article-c-1-1 {
    padding: 2vh 2vw;
}

.Article-title {
    font-size: 2rem;
    font-weight: 600;
}

.Article-section {
    font-size: 1.2rem;
    font-weight: 500;
}

.Article-date {
    font-weight: 400;
}

.Article-text {
    font-size: 1rem;
    margin: 2vh 2vw;
}


.Article-c-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 4% 0;
 }

 .Article-c-2-1,
 .Article-c-2-2 {
    background-color: #29292a;
    width: 48%;
    border-radius: 5px;
    color: rgb(247, 247, 247);
    font-family: 'Inter', sans-serif;
    font-size: 1.1rem;
 }

 .Article-c-2-1 {
    min-height: 60vh;
    height: fit-content;
 }

 .Article-c-2-2 {
    padding: 1vh 0;
    display: flex;
    align-items: center;
    flex-flow: column;
 }


 .Article-images {
    max-height: 30vh;
    width: 60%;
    margin: 2vh 3vw;
    border-radius: 5px;
 }

 .Article-links {
    display: block;
    text-decoration: none;
    color: rgb(118, 115, 115);
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    margin: 2vh 2vw;
 }

 @media (max-width: 800px) {
    .Article-c-2 {
        flex-direction: column;
        align-items: center;
    }
    .Article-title {
        font-size: 1.8rem;
    }
    .Article-c-2-1,
    .Article-c-2-2 {
        width: 85%;
        margin: 2vh 0;
    }
    .Article-c-1 {
        width: 85%;
        margin: 0 auto;
    }
 }

 @media (max-width: 600px) {
    .Article-text,
    .Article-links {
        font-size: 0.7rem;
    }
    .Article-title {
        font-size: 1.5rem;
    }
    .Article-images {
        width: 80%;
     }
 }

 @media (max-width: 600px) {
    .Article-text,
    .Article-links {
        font-size: 0.7rem;
    }
    .Article-title {
        font-size: 1.2rem;
    }
 }