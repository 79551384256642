.Section-header {
    font-family: 'Roboto', sans-serif;
    font-size: 2.6rem;
    font-weight: 500;
    text-align: center;
}

.Section {
    margin: 0 auto;
    max-width: 1200px;
    height: 250px;
    margin-bottom: 10.5vh;
    margin-top: 2.5vh;
    display: flex;
    align-items: center;
}


.first-section-span {
    color: #F17171;
}

.second-section-span {
    color: #9B90FF;
}

.third-section-span {
    color: #D39113;
} 

@media (max-width: 970px) {
    .Section-header {
        font-size: 1.8rem;
    }
}


@media (max-width: 730px) {
    .Section-header {
        margin-top: 2vh;
        font-size: 1.5rem;
    }
    .Section {
        height: 25vh;
    }
}

@media (max-width: 530px) {

    .Section {
        height: 20vh;
    }
}

@media (max-width: 400px) {

    .Section {
        height: 16vh;
        margin-bottom: 6vh;
    }

    .Section-header {
        margin-top: 1.5vh;
    }
}