.SectionChildTag {
    height: 65%;
    padding: 1% 3.5%;
    margin: 0% 1%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    color: white;
    background-color: #525252;
}

.SectionChildTag p {
    font-family: 'Inter', sans-serif;
    font-size: 0.7rem;
}


@media (max-width: 950px) {
    .SectionChildTag p {
        font-size: 0.7rem;
    }
}

@media (max-width: 700px) {
    .SectionChildTag {
        display: none;
    }
}