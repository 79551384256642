.Hero {
    padding: 2vh 5%;
}

.Hero-child-one {
    max-width: 1200px; 
    margin: 0 auto;
    margin-top: 6vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Hero-child-one-one {
    color: #312929;
    margin-left: 5%;
}

.Hero-child-one-two {
    margin-right: 5%;
    max-width: 20%;
}

.Hero-child-text {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 3.5rem;
}

.Hero-child-one-two img {
    width: 100%;
}

.Hero-black {
    color: black;
}

.Hero-child-three {
    max-width: 1050px;
    margin: 0 auto;
    text-align: center;
    margin-top: 6vh;
    margin-bottom: 6vh;
}

.Hero-sub-text {
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
    font-size: 1.3rem;
    color: rgb(17, 17, 17);
}

.Hero-sub-text-bold {
    font-family: 'Open sans', sans-serif;
    font-size: 1.6rem;
    color: rgb(17, 17, 17);
    font-weight: 600;
}

.Hero-elip {
    color: #FF5678;
}


@media (max-width: 980px) {
    .Hero-child-text {
        font-size: 2.5rem;
    }
    .Hero-child-one {
        margin-top: 7vh;
    }
    .Hero-sub-text {
        font-size: 1.1rem;
    }
    .Hero-sub-text-bold {
        font-size: 1.4rem;
    }
    .Hero-child-three {
        margin-bottom: 4vh;
    }
}

@media (max-width: 530px) {
    .Hero-child-text {
        font-size: 2.5rem;
    }
    .Hero-child-one-one {
        margin-bottom: 3vh;
    }
    .Hero-child-one-two {
        margin: 0;
        max-width: 35%;
    }
    .Hero-child-one {
        flex-direction: column;
    }
    .Hero-child-two {
        margin-top: 4vh;
    }
    .Hero-child-three {
        margin-top: 4vh;
        margin-bottom: 2vh;
    }
    .Hero-sub-text {
        font-size: 0.9rem;
    }

}

@media (max-width: 400px) {
    .Hero-child-text {
        font-size: 2rem;
    }
}