.first-section {
    border: 7px solid #FF716B;
}

.first-section-title {
    color: #FF716B;
}

.second-section {
    border: 7px solid #9B90FF;
}

.second-section-title {
    color: #9B90FF;
}

.third-section {
    border: 7px solid #D39113;
}

.third-section-title {
    color: #D39113;
}

.SectionChild {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    background-color: #241F1F;
    width: 18%;
    height: 85%;
    margin: 0 3%;
}

.SectionChild-title {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    text-align: center;
    font-size: 1.8rem;
}



.SectionChild-blurb {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    text-align: center;
    font-size: 0.7rem;
    color: #D6D4D4;
}


.SectionChild-c-1 {
    padding: 2% 0.5vw;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 15%;
}

.SectionChild-c-2 {
    height: 15%;
    display: flex;
    align-items: center;
    padding: 0.25vw 0.25vw;
}

.SectionChild:hover {
    cursor: pointer;
}

@media (max-width: 950px) {
    .SectionChild-title {
        font-size: 1.2rem;
    }
    .SectionChild-blurb {
        font-size: 0.8rem;
    }
}

@media (max-width: 700px) {
    .SectionChild-title {
        font-size: 1rem;
    }
    .SectionChild-blurb {
        font-size: 0.7rem;
    }
    .SectionChild-c-1 {
        height: 40%;
    }
    .SectionChild-c-3 {
        height: 0%;
    }
    .SectionChild-c-2 {
        margin-bottom: 1vh;
        height: 60%;
    }
}

@media (max-width: 500px) {
    .SectionChild-title {
        font-size: 1rem;
        padding: 0.5vh 0;
    }
    .SectionChild-blurb {
        font-size: 0.6rem;
    }
    .SectionChild-c-1 {
        height: 50%;
    }
    .SectionChild-c-2 {
        margin-bottom: 1vh;
        height: 40%;
    }
}

@media (max-width: 400px) {
    .SectionChild-title {
        font-size: 0.8rem;
    }
    .SectionChild-blurb {
        font-size: 0.45rem;
    }
    .SectionChild-c-2 {
        margin: 0;
    }
    .SectionChild-c-1 {
        padding: 0 0.5vw;
    }


}