.Footer {
    height: 25vh;
    min-height: 350px;
    padding: 10px;
    background-color: #002734;
    text-align: center;
    color: white;
    font-family: 'Open Sans', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.title {
    font-size: 1.5rem;
    letter-spacing: 0.3rem;
}

.title span {
    color: rgb(194, 194, 194);
    font-weight: 500;
}


.list-item {
    font-size: 1rem;
    display: inline;
    padding: 0 25px;
    letter-spacing: 0.2rem;
}

.list-item a {
    text-decoration: none;
    color: white;
}

.list-item a:hover {
    cursor: pointer;
}

.location {
    font-size: 0.9rem;
    letter-spacing: 0.15rem;
}

.title,
.list,
.location {
    padding: 1.8vh 0;
}


@media (max-width: 730px) {
    .title {
        font-size: 1rem;
    }
    .quote {
        font-size: 0.9rem;
    }
    .note {
        font-size: 0.8rem;
        max-width: 500px;
    }
    .list {
        padding-top: 30px;
    }
    .list-item {
        padding: 0 20px;
        font-size: 0.9rem;
    }
    .Footer {
        height: 30vh;
        padding: 5px;
    }
}

@media (max-width: 400px) {
    .list-item {
        font-size: 0.8rem;
        padding: 0 15px;
    }
}

