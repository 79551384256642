.Header {
    background-color: rgb(255, 255, 255);
    position: fixed;
    width: 100%;
    height: 25px;
    padding: 1.5vh 0;
    padding-bottom: 2vh;
}

.Header-child {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
}

.Header-child-logo {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
}

.Header-child-logo span {
    color: rgb(75, 74, 74);
    font-weight: 500;
}

.Header-child-contact {
    background-color: #000000;
    border-radius: 3px;
    color: #fff;
    height: 30px;
    font-size: 0.8rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
}

.Header-child-contact:hover {
    cursor: pointer;
    background-color: #FF5678;
}

.Header-child-contact p {
    padding: 6px 10px;
}

.Header-child-logo:hover {
    cursor: pointer;
}

@media (max-width: 1200px) {
    .Header-child-contact {
        margin-right: 2%;
    }
}

@media (max-width: 530px) {
    .Header-child-logo {
        font-size: 1rem;
    }
    .Header-child-contact {
        height: 25px;
        font-size: 0.7rem;
    }
    .Header-child-contact p {
        padding-top: 5px
    }
}

@media (max-width: 420px) {
    .Header-child-logo {
        font-size: 0.9rem;
    }
    .Header-child-contact {
        font-size: 0.6rem;
    }
    .Header-child-contact p {
        padding-top: 6px;
    }
}

@media (max-width: 350px) {
    .Header-child-contact {
        font-size: 0.55rem;
    }
    .Header-child-contact p {
        padding-top: 7px;
    }
}