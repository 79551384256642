*,
html,
body {
    padding: 0;
    margin: 0;
}

body {
    background-color: rgb(254, 254, 254);
}

.data-fetch-msg {
    text-align: center;
    font-family: 'Roboto-mono', sans-serif;
    font-size: 2rem;
    margin: 5vh 0 15vh 0;
}